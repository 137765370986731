<template>
	<please-select-utility alert-class="ma-3">
		<data-loader
			:watch="{utility}"
			:callback="getCurrentSession"
			:data.sync="selectedSession">
			<template #loading>
				<v-progress-linear indeterminate rounded/>
			</template>
			<template #default>
				<v-alert
					v-if="!selectedSession"
					colored-border border="left" elevation="3"
					type="error" class="ma-3"
				> Няма активна сесия за текущото дружество!
				</v-alert>
				<component :imodel="model" v-else-if="session && selectedSession" :is="component" v-bind="$route.params" />
			</template>
		</data-loader>
	</please-select-utility>
</template>

<script>
import DataLoader from "@/ittijs/components/DataLoader.vue";
import PleaseSelectUtility from "@/ittijs/components/PleaseSelectUtility.vue";
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import RouteSyncMixin from "@/ittijs/RouteSyncMixin";
import ITTIModelProvider from "@/ittijs/ITTIModelProvider";
import {Mesechen} from "@/views/otcheti/otchetnik_ui/Model";
import DailySchedule from "@/views/otcheti/otchetnik_ui/DailySchedule.vue";
import StationView from "@/views/otcheti/otchetnik_ui/StationView.vue";
import ImotView from "@/views/otcheti/otchetnik_ui/ImotView.vue";

const model = ITTIModelProvider.getModel(Mesechen);

export default {
	components: {PleaseSelectUtility, DataLoader, DailySchedule, StationView, ImotView},
	mixins: [
		SelectedUtilityMixin,
		RouteSyncMixin({
			utility: 'selectedUtility',
			session: 'selectedSession',
		}),
	],
	props: [
		'utility',
		'session',
	],
	data() {
		return {
			model,
			selectedSession: this.session,
		}
	},
	computed: {
		component(){
			if(this.$route.params.imot){
				return 'ImotView';
			}
			if(this.$route.params.station){
				return 'StationView';
			}
			return 'DailySchedule';
		},
	},
	methods: {
		async getCurrentSession() {
			this.selectedSession = await model.fetch('getCurrentSession', {utility: this.utility});
		}
	},
}
</script>